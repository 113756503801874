//import Accordions from "./accordions";
import PbRowAccordions from "./pb-row-accordions";
//import FilteredPostsList from "./filtered-posts-list";
//import GoogleMap from "./google-map";
//import Lottie from "./lottie";
//import Newsletter from "./newsletter";
//import PbRowMedias from "./pb-row-medias";
//import PbRowOembed from "./pb-row-oembed";
//import PbRowTabs from "./pb-row-tabs";
import PbRowTestimonials from "./pb-row-testimonials";
//import PbRowWrapperReveal from "./pb-row-wrapper-reveal";
//import PrefersColorSchemeBtn from "./prefers-color-scheme-btn";
//import Sharing from "./sharing";
//import SvgPathLength from "./svg-path-length";
//import TextTicker from './text-ticker';
import Video from "./video";

export default {
  //Accordions,
  PbRowAccordions,
  //FilteredPostsList,
  //GoogleMap,
  //Lottie,
  //Newsletter,
  //PbRowMedias,
  //PbRowOembed,
  //PbRowTabs,
  PbRowTestimonials,
  //PbRowWrapperReveal,
  //PrefersColorSchemeBtn,
  //Sharing,
  //SvgPathLength,
  //TextTicker,
  Video,
};
