export const createShader = (gl, type, source) => {
  const shader = gl.createShader(type);
  gl.shaderSource(shader, source);
  gl.compileShader(shader);

  var success = gl.getShaderParameter(shader, gl.COMPILE_STATUS);

  if (success) return shader;

  console.log(type === gl.vertexShader ? 'vertex' : 'fragment', gl.getShaderInfoLog(shader));
  gl.deleteShader(shader);
}

export const createVertexShader = (gl, source) => createShader(gl, gl.VERTEX_SHADER, source);
export const createFragmentShader = (gl, source) => createShader(gl, gl.FRAGMENT_SHADER, source);

export const createProgram = (gl, vertexShader, fragmentShader) => {
  var program = gl.createProgram();
  gl.attachShader(program, vertexShader);
  gl.attachShader(program, fragmentShader);
  gl.linkProgram(program);
  
  var success = gl.getProgramParameter(program, gl.LINK_STATUS);
  if (success) return program;
 
  console.log(gl.getProgramInfoLog(program));
  gl.deleteProgram(program);
}

export default {
  createShader,
  createVertexShader,
  createFragmentShader,
  createProgram,
}
