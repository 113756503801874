import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';

import { $ } from '@utils/dom';

const SWIPER_OPTIONS = {
  modules: [Pagination],
  centeredSlides: false,
  freeMode: false,
  loop: false,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    type: 'bullets',
  },
  slidesPerView: 'auto',
  slidesOffsetAfter: 12,
  slidesOffsetBefore: 12,
  spaceBetween: 12,
  breakpoints: {
    768: {
      slidesOffsetAfter: 20,
      slidesOffsetBefore: 20,
      spaceBetween: 12,
    },
    1200: {
      slidesOffsetAfter: 40,
      slidesOffsetBefore: 40,
      spaceBetween: 20,
    }
  }
};

class PbRowTestimonials {
  constructor(el, emitter) {
    this.el = el;
    this.emitter = emitter;
    this.slider = $('.pb-row-testimonials__slider', this.el);

    console.log('adfasd', this.slider);
  }

  init() {
    if( this.slider ) this.slider = new Swiper(this.slider, SWIPER_OPTIONS);
  }
  destroy() {
    if( this.slider ) this.slider.destroy();

    this.el = null;
    this.emitter = null;
    this.slider = null;
  }
}

export default PbRowTestimonials;
