import { $, html } from "@utils/dom";
import { on, off } from "@utils/listener";
import { moduleDelays } from "./utils";

const SELECTOR = "[data-site-loader]";

class SiteLoader {
  constructor() {
    this.el = $(SELECTOR);

    this._promise = null;
    this._onAnimateOutCompleted = this._onAnimateOutCompleted.bind(this);
  }

  loaded() { moduleDelays(350, 350); }
  ready() {
    return new Promise((resolve) => {
      this._promise = resolve;

      on(this.el, 'transitionend', this._onAnimateOutCompleted);
      this.el.classList.add('--animate-out');
    });
  }

  _onAnimateOutCompleted() {
    off(this.el, 'transitionend', this._onAnimateOutCompleted);

    // remove from DOM when completed
    if( this.el ) this.el.remove();

    // add class on html when transition is ready
    html.classList.add("--js-ready");

    // resolve transition
    this._promise();

    // destroy all instanciations
    this.el = null;
    this._promise = null;
    this._onAnimateOutCompleted = null;
  }
}

export default SiteLoader;
