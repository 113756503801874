import { $ } from "@utils/dom";
import { on } from "@utils/listener";
import ResizeOrientation from "@utils/resize";


class SiteFooter {
  constructor(el, emitter) {
    this.el = el;
    this.emitter = emitter;

    this.wrapper = $('.site-footer__wrapper', this.el);

    this._initialized = false;
    this._onResize = this._onResize.bind(this);
  }

  init() {
    if( this._initialized ) return;
    this._initialized = true;

    this._bindEvents();
    this._onResize();
  }

  _bindEvents(){
    ResizeOrientation.add(this._onResize);
  }

  _onResize(){
    this.el.style.setProperty('--wrapper-height', `${this.wrapper.offsetHeight}px`);
    // this.nav.classList.toggle('--js-opened');
  }
}

export default SiteFooter;
