import { $ } from "@utils/dom";
import { on } from "@utils/listener";

class SiteHeader {
  constructor(el, emitter) {
    this.el = el;
    this.emitter = emitter;
    this.navToggler = $('.site-header__navToggler', this.el);
    this.nav = $('.site-header__nav', this.el);

    this._initialized = false;
    this._toggleNav = this._toggleNav.bind(this);
  }

  init() {
    if( this._initialized ) return;
    this._initialized = true;

    this._bindEvents();
  }

  _bindEvents(){
    if (this.navToggler) on(this.navToggler, 'click', this._toggleNav);
  }

  _toggleNav(){
    this.nav.classList.toggle('--js-opened');
  }
}

export default SiteHeader;
