import Swiper from 'swiper';
import { EffectCreative } from "swiper/modules";

import { $, $$ } from "@utils/dom";
import { on, off } from '@utils/listener';


const SWIPER_OPTIONS = {
  modules: [EffectCreative],
  effect: 'creative',
  creativeEffect: {
    next: {
      scale: 1.2,
      opacity: 0
    },
    prev: {
      scale: 1.2,
      opacity: 0
    }
  },
  centeredSlides: false,
  freeMode: false,
  allowTouchMove: false,
  loop: true,
  resistance: false,
  slidesPerView: 1,
  speed: 650
};

class PbRowAccordions {
  constructor(el, emitter) {
    this.el = el;
    this.emitter = emitter;

    this.accordions = [ ...$$('.accordions__accordion', this.el) ];
    this.slider = $(".pb-row-accordions__imgSlider", this.el);

    this._onAccordionToggle = this._onAccordionToggle.bind(this);
  }

  init() {
    if( this.slider ) this.swiper = new Swiper(this.slider, SWIPER_OPTIONS);

    this._bindEvents();
  }
  destroy() {
    this._unbindEvents();

    if( this.swiper ) this.swiper.destroy(true, false);

    this.el = null;
    this.emitter = null;
    this.accordions = null;
    this.slider = null;
    this.swiper = null;

    this._onAccordionToggle = null;
  }

  _bindEvents() {
    if( this.accordions ) on(this.accordions, 'toggle', this._onAccordionToggle);
  }
  _unbindEvents() {
    if( this.accordions ) off(this.accordions, 'toggle', this._onAccordionToggle);
  }

  _onAccordionToggle(event) {
    if (event.currentTarget.hasAttribute('open')) {
      this.accordions.forEach((accordion, index ) => {
        if (accordion != event.currentTarget) {
          accordion.open = false;
        } else {
          if (this.swiper) this.swiper.slideTo(index);
        }
      });
    }

    this.emitter.emit('SiteScroll.update');
  }
}

export default PbRowAccordions;
