import Gdpr from "./gdpr";
import GForm from "./gform";
//import MouseWheelFacebook from "./mouse-wheel-facebook";
//import MouseWheelVimeo from "./mouse-wheel-vimeo";
//import MouseWheelYoutube from "./mouse-wheel-youtube";
import SiteBackground from "./site-background";
import SiteHeader from "./site-header";
import SiteFooter from "./site-footer";
//import SiteNav from "./site-nav";
//import SiteVideo from "./site-video";

// const Gdpr = GDPR;

export default {
  Gdpr,
  GForm,
  //MouseWheelFacebook,
  //MouseWheelVimeo,
  //MouseWheelYoutube,
  SiteBackground,
  SiteHeader,
  SiteFooter,
  //SiteNav,
  //SiteVideo,
};
