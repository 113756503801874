import { on } from "@utils/listener";
import { lerp } from "@utils/math";
import { mobile } from "@utils/mobile";
import RAF from "@utils/raf";
import Viewport from "@utils/viewport";
import { createVertexShader, createFragmentShader, createProgram } from "@utils/webgl";


const VERTEX_SHADER = `
  attribute vec2 a_position;
  varying vec2 v_position;

  void main() {
    // convert from 0->1 to 0->2
    vec2 zeroToTwo = a_position * 2.0;

    // convert from 0->2 to -1->+1 (clip space)
    vec2 clipSpace = zeroToTwo - 1.0;

    gl_Position = vec4(clipSpace * vec2(1, -1), 0, 1);
    v_position = a_position;
  }
`;
const FRAGMENT_SHADER = `
  precision mediump float;

  uniform vec2 u_mouse;
  uniform float u_time;
  uniform float u_scroll;

  varying vec2 v_position;

  struct RadialGradient {
    float radius;
    vec3 color;
    float alpha;
    vec2 point;
  };

  vec2 getPoint(float x, float y, vec2 timeFactor, vec2 mouseFactor, float scrollFactor) {
    return vec2(
      x + sin(u_time) * timeFactor.x + u_mouse.x * mouseFactor.x, 
      y + sin(u_time) * timeFactor.y + u_mouse.y * mouseFactor.y + u_scroll * scrollFactor
    );
  }

  void main() {
    // colors
    vec3 colors[3];
    colors[0] = vec3(.87843137, .96862745, 1.);             // pattens blue
    colors[1] = vec3(.97647059, .97254902, .85882353);      // yellow
    colors[2] = vec3(.02352941, .30588235, .39607843);      // teal blue

    // gradients
    const int numGradients = 3;
    RadialGradient gradients[numGradients];
    gradients[0] = RadialGradient( .7,  colors[1], 1., getPoint(.11, .08,  vec2(.13, .34),   vec2(-.1, .08), .32) );
    gradients[1] = RadialGradient( .89, colors[2], .3, getPoint(.92, 1.05, vec2(.09, -.71),  vec2(.2, -.15), -.1) );
    gradients[2] = RadialGradient( .52, colors[1], 1., getPoint(-.1, 1.11, vec2(.04, -.14),  vec2(.12, .2), .05) );

    // base color
    vec3 color = vec3(colors[0]);

    // blend gradients
    for(int i = 0; i < numGradients; ++i) {
      color = mix(
        color, 
        gradients[i].color,
        //gradients[i].alpha * step(gradients[i].radius * -1., distance(gradients[i].point, v_position.xy) * -1.)
        gradients[i].alpha * smoothstep(gradients[i].radius, 0.0, distance(gradients[i].point, v_position.xy))
      );
    }

    gl_FragColor = vec4(color, 1.);
  }
`;

const MOUSE_LERP = 0.1;

class SiteBackground {
  constructor(el, emitter) {
    this.el = el;
    this.emitter = emitter;

    this._initialized = false;
    this._context = null;
    this._vertexShader = null;
    this._fragmentShader = null;
    this._program = null;
    this._attributes = { position: null };
    this._buffers = { position: null };
    this._uniforms = { mouse: null, time: null, scroll: null };
    this._data = { mouse: {x: 0.0, y: 0.0, tx: 0.0, ty: 0.0}, time: 0.0, scroll: 0.0 };

    this._onRAF = this._onRAF.bind(this);
    this._onMouseMove = this._onMouseMove.bind(this);
    this._onScroll = this._onScroll.bind(this);
  }

  init() {
    if( this._initialized ) return;
    this._initialized = true;

    this._createGL();
    if( !this._context ) return;

    RAF.add(this._onRAF, 0, true);
    on(window, 'mousemove', this._onMouseMove);
    this.emitter.on('SiteScroll.scroll', this._onScroll);
  }

  _onRAF(delta) {
    // Clear the canvas
    this._context.clearColor(0, 0, 0, 0);
    this._context.clear(this._context.COLOR_BUFFER_BIT);

    // Tell it to use our program (pair of shaders)
    this._context.useProgram(this._program);

    // Turn on the position attribute
    this._context.enableVertexAttribArray(this._attributes.position);

    // Bind the position buffer.
    this._context.bindBuffer(this._context.ARRAY_BUFFER, this._buffers.position);

    // Tell the attribute how to get data out of position's buffer (ARRAY_BUFFER)
    this._context.vertexAttribPointer(this._attributes.position, 2, this._context.FLOAT, false, 0, 0);

    // update data
    if( !mobile ) {
      const hvw = Viewport.width / 2;
      const hvh = Viewport.height / 2;

      this._data.mouse.x = lerp(this._data.mouse.x, (this._data.mouse.tx - hvw) / hvw, MOUSE_LERP * delta);
      this._data.mouse.y = lerp(this._data.mouse.y, (this._data.mouse.ty - hvh) / hvh, MOUSE_LERP * delta);
    }

    this._data.time += (0.005 * delta);

    // Set uniforms
    this._context.uniform2f(this._uniforms.mouse, this._data.mouse.x, this._data.mouse.y);
    this._context.uniform1f(this._uniforms.time, this._data.time);
    this._context.uniform1f(this._uniforms.scroll, this._data.scroll);

    // Draw the rectangle.
    this._context.drawArrays(this._context.TRIANGLES, 0, 6);
  }
  _onMouseMove(event) {
    this._data.mouse.tx = event.pageX;
    this._data.mouse.ty = event.pageY;
  }
  _onScroll({ progress }) { this._data.scroll = progress; }

  _createGL() {
    // try to create WebGL context
    // if it fail, stop here
    if( !this._context ) this._context = this.el.getContext('webgl');
    if( !this._context ) return;

    // set viewport
    this._context.viewport(0, 0, 512, 512);

    // create shaders & program
    if( !this._vertexShader ) this._vertexShader = createVertexShader(this._context, VERTEX_SHADER);
    if( !this._fragmentShader ) this._fragmentShader = createFragmentShader(this._context, FRAGMENT_SHADER);
    if( !this._program ) this._program = createProgram(this._context, this._vertexShader, this._fragmentShader);

    // attributes
    if( !this._attributes.position ) this._attributes.position = this._context.getAttribLocation(this._program, "a_position");

    // buffers
    if( !this._buffers.position ) this._buffers.position = this._context.createBuffer();

    // uniforms
    if( !this._uniforms.mouse ) this._uniforms.mouse = this._context.getUniformLocation(this._program, "u_mouse");
    if( !this._uniforms.time ) this._uniforms.time = this._context.getUniformLocation(this._program, "u_time");
    if( !this._uniforms.scroll ) this._uniforms.scroll = this._context.getUniformLocation(this._program, "u_scroll");

    // bind position buffer & create a rectangle matching video's intrinsic size to buffer
    this._context.bindBuffer(this._context.ARRAY_BUFFER, this._buffers.position);
    this._createRectangle(0, 0, 1, 1);
  }
  _createRectangle(x, y, width, height) {
    const x1 = x;
    const x2 = x + width;
    const y1 = y;
    const y2 = y + height;

    this._context.bufferData(
      this._context.ARRAY_BUFFER, 
      new Float32Array([
        x1, y1,
        x2, y1,
        x1, y2,
        x1, y2,
        x2, y1,
        x2, y2,
      ]
    ), this._context.STATIC_DRAW);
  }
}

export default SiteBackground;
