import { moduleDelays } from "./utils";

class SiteTransition {
  exit({ current }) {
    current.container.classList.add("pointer-events-none");

    // wait 400ms, then resolve
    return new Promise(resolve => setTimeout(resolve, 400));
  }

  entering() { moduleDelays(350, 100); }

  // required for windmill to select this transition, even if function is empty, leave it here
  enter() {}
}

export default SiteTransition;
